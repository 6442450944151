import { Modal } from "antd";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { showMessege } from "../components/CustomMessage";
import ManualQuestionnaire from "../pages/ManualQuestionnaire";
import ReduceRate from "../pages/ReduceRate";
import { ActionCreators } from "../store";
import { BookingApi } from "../store/api";
import Loader from "../components/Loader";
import AccountSetup from "../pages/AccountSetup";
import googleTagFire from "../utills/googleTagFire";
import AccountDelete from "../pages/DeleteAccount";

const AppointmentConfirmation = lazy(() =>
  import("../pages/AppointmentConfirmation")
);
const AppointmentOverview = lazy(() => import("../pages/AppointmentOverview"));
const BrowseTherapist = lazy(() => import("../pages/BrowseTherapist"));
const ConfirmBooking = lazy(() => import("../pages/ConfirmBooking"));
const MatchingQuestionnaire = lazy(() =>
  import("../pages/MatchingQuestionaries")
);
const MatchingTools = lazy(() => import("../pages/MatchingTools"));
const RecommendedMatches = lazy(() => import("../pages/RecommendedMatches"));
const SelectDateTime = lazy(() => import("../pages/SelectDateTime"));
const SelectSessionDuration = lazy(() =>
  import("../pages/SelectSessionDuration")
);
const SelectStudio = lazy(() => import("../pages/SelectStudio"));
const IntakeForm = lazy(() =>
  import("../pages/Settings/Forms/intakeForm/IntakeForm")
);
const SettingsScreen = lazy(() => import("../pages/Settings/SettingsScreen"));
const SignIn = lazy(() => import("../pages/SignIn"));
const SignUp = lazy(() => import("../pages/SignUp"));
const TherapistDetail = lazy(() => import("../pages/TherapistDetail"));
const TherapyTab = lazy(() => import("../pages/TherapyTab"));
const DirectBilling = lazy(() =>
  import("../pages/Settings/Billing/DirectBilling")
);
const CustomAuth = lazy(() => import("../pages/CustomAuth/CustomAuth"));
const MandatoryFields = lazy(() => import("../components/MandatoryFields"));

export default function Navigations() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uniqueId = searchParams.get("ID");

  const loginStatus = useSelector((state) => state.AuthReducer.loginStatus); //store data
  const gotoReduceRate = useSelector(
    (state) => state.AuthReducer.gotoReduceRate
  );
  const storeUniqueId = useSelector(
    (state) => state.ConfirmBookingDataReducer?.uniqueId
  );
  const therapyReducer = useSelector((state) => state.TherapyReducer);
  const authReducer = useSelector((state) => state.AuthReducer);
  const { getConsentFormStatus, setUniqueId, setConfirmBookingDataAction } =
    bindActionCreators(ActionCreators, dispatch);
  const [showMandatoryModal, setshowMandatoryModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const currentPage = location.pathname;

  useEffect(() => {
    googleTagFire("Page View", "Route", location?.pathname);
    if (
      location.pathname === "/waitlist" &&
      uniqueId !== null &&
      uniqueId !== undefined &&
      uniqueId !== ""
    ) {
      fetchGoToDateTimeRoute(uniqueId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, location.pathname]);

  useEffect(() => {
    if (
      storeUniqueId !== null &&
      storeUniqueId !== undefined &&
      storeUniqueId !== "" &&
      loginStatus
    ) {
      fetchGoToDateTimeRoute(storeUniqueId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUniqueId, loginStatus]);

  const fetchGoToDateTimeRoute = async (id) => {
    if (loginStatus) {
      const res = await BookingApi.getSelectDateTimeData(id);
      if (res?.status === 200) {
        setTimeout(() => {
          setConfirmBookingData(res);
        }, 500);
      } else {
        setUniqueId(null);
        showMessege(
          "error",
          res?.data?.details ?? "Could not validate credentials!"
        );
        setLoading(false);
      }
    } else {
      showMessege("error", "Please Login to book the Appointment!");
      setUniqueId(id);
      navigate("/sign-in");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loginStatus) {
      if (window.localStorage.getItem("submission_id")) {
        getAssignFormData();
      }
      getConsentFormStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  useEffect(() => {
    if (loginStatus && currentPage !== "/account-setup") {
      if (
        authReducer?.userInfo?.first_name === "" ||
        authReducer?.userInfo?.last_name === "" ||
        authReducer?.userInfo?.date_of_birth === null
      ) {
        setshowMandatoryModal(true);
      } else {
        setshowMandatoryModal(false);
      }
    } else {
      setshowMandatoryModal(false);
    }
  }, [authReducer, loginStatus, currentPage]);

  const getAssignFormData = async () => {
    const response = await BookingApi.getAssignFormData({
      submission_id: window.localStorage.getItem("submission_id"),
    });
    if (response?.status === 201) {
      window.localStorage.removeItem("submission_id");
      dispatch(ActionCreators.getTherapyIndex());
    }
  };

  const setConfirmBookingData = (res) => {
    let data = {
      instructor: res?.data?.instructor?.id,
      instructor_name:
        res?.data?.instructor?.first_name +
        " " +
        res?.data?.instructor?.last_name,
      instructor_pronoun: res?.data?.instructor?.instructor?.pronoun,
      instructor_designation: res?.data?.instructor?.instructor?.designation,
      instructor_profile_photo: res?.data?.instructor?.instructor?.image_path,
      start_at: null,
      available_at: res?.data?.available_at,

      appointment_type: {
        name: res?.data?.appointment_type?.appointment_type?.name,
        label: res?.data?.appointment_type?.appointment_type?.label,
        key: res?.data?.appointment_type?.appointment_type?.key,
      },
      appointment_location: {
        name: res?.data?.appointment_type?.appointment_location?.name,
        label: res?.data?.appointment_type?.appointment_location?.label,
        key: res?.data?.appointment_type?.appointment_location?.key,
      },
      appointment_age_category: {
        name: res?.data?.appointment_type?.appointment_age_category?.name,
        label: res?.data?.appointment_type?.appointment_age_category?.label,
        key: res?.data?.appointment_type?.appointment_age_category?.key,
      },
      appointment_duration: {
        name: res?.data?.appointment_type?.appointment_duration?.name,
        label: res?.data?.appointment_type?.appointment_duration?.label,
        key: res?.data?.appointment_type?.appointment_duration?.key,
      },
      studio_location: res?.data?.studio_location,
      appointment_type_id: res?.data?.appointment_type?.id,
    };
    setConfirmBookingDataAction(data);
    navigate("/select-date-time", { replace: true });
    // redirect("/select-date-time");
    setLoading(false);
  };

  const getInitialRoute = () => {
    if (location.pathname === "/custom-auth") {
      return <CustomAuth />;
    }
    if (location.pathname === "/account-setup") {
      return <AccountSetup />;
    }
    if (gotoReduceRate) {
      return <ReduceRate />;
    }
    if (window.localStorage.getItem("instructor_id") && loginStatus) {
      return <TherapyTab />;
    } else if (window.localStorage.getItem("submission_id")) {
      return <RecommendedMatches />;
    } else {
      if (therapyReducer?.result?.patient_profile?.instructor && loginStatus) {
        return <TherapyTab />;
      } else if (therapyReducer?.result?.appointment && loginStatus) {
        return <TherapyTab />;
      } else if (therapyReducer?.result?.recommended_instructors?.length > 0) {
        return <RecommendedMatches />;
      } else {
        return <MatchingTools />;
      }
    }
  };

  if (loading) {
    return (
      <div style={{ height: "82vh", width: "100%" }}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Suspense fallback={null}>
        <Routes>
          <Route path="*" element={getInitialRoute()} />
          <Route path="/matching-tools" element={<MatchingTools />} />
          <Route path="/select-studio" element={<SelectStudio />} />
          <Route path="/recommended-list" element={<RecommendedMatches />} />
          <Route
            path="/manual-questionnaire"
            element={<ManualQuestionnaire />}
          />
          <Route path="/browse-therapist/:id" element={<BrowseTherapist />} />
          <Route
            path="/matching-questionnaire/:id"
            element={<MatchingQuestionnaire />}
          />
          <Route path="/therapist-detail/:id" element={<TherapistDetail />} />
          {/* <Route path="/request-location" element={<RequestLocation />} /> */}

          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/therapy" element={<TherapyTab />} />
          <Route path="/reduced-rate" element={<ReduceRate />} />
          <Route
            path="/appointment-confirmation"
            element={<AppointmentConfirmation />}
          />
          <Route path="/delete-account" element={<AccountDelete />} />
          {loginStatus && (
            <>
              <Route
                path="/select-session-duration"
                element={<SelectSessionDuration />}
              />
              <Route path="/select-date-time" element={<SelectDateTime />} />
              <Route path="/confirm-appointment" element={<ConfirmBooking />} />

              <Route path="/account-setup" element={<AccountSetup />} />
              <Route
                path="/appointment-overview/:id"
                element={<AppointmentOverview />}
              />
              <Route path="/settings" element={<SettingsScreen />} />
              <Route
                path="/settings/insurance-and-direct-billing/:id"
                element={<DirectBilling />}
              />
              <Route path="/intake-form" element={<IntakeForm />} />
              <Route path="/custom-auth" element={<CustomAuth />} />
            </>
          )}
        </Routes>
      </Suspense>
      <Modal
        open={showMandatoryModal}
        centered={true}
        title={"Please Provide Following Information(s)."}
        closable={false}
        width={600}
        onCancel={() => {}}
        footer={<></>}
      >
        <MandatoryFields />
      </Modal>
    </>
  );
}
