import "./App.css";
import "./antdCustomStyle.scss";
import "video-react/styles/scss/video-react.scss";
import { ConfigProvider, Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { showMessege } from "./components/CustomMessage";
import Header from "./components/Header";
import Navigations from "./routes";
import { ActionCreators } from "./store";
import { AuthApi } from "./store/api";
import { setToken } from "./store/api/config/AxiosConfig";
import { SQUARE_URL } from "./store/api/config/config";
import { auth } from "./utills/firebase-config";
import Loader from "./components/Loader";
import { signOut } from "firebase/auth";
import googleTagFire from "./utills/googleTagFire";
import "react-phone-input-2/lib/style.css";
import { GlobalDebug } from "./remove_console";

const { Content } = Layout;

function App() {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSignedIn, setisSignedIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const { setJwt, setInfo, setLoginLoader } = bindActionCreators(
    ActionCreators,
    dispatch
  );
  const [deleteAccount, setDeleteAccount] = useState(false);

  useEffect(() => {
    if (location.pathname === "/delete-account") {
      setDeleteAccount(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = SQUARE_URL;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "production"
    ) {
      GlobalDebug(false);
    }
  }, []);

  const authHandle = async (user) => {
    const fullName = localStorage.getItem("name");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    if (user) {
      if (
        user?.email !== null &&
        user?.email !== undefined &&
        user?.email !== ""
      ) {
        setLoginLoader(true);
        const res = await AuthApi.login({
          fire_token: user?.accessToken,
          preferred_name: fullName ?? user?.displayName ?? null,
          first_name: firstName ?? null,
          last_name: lastName ?? null,
        });
        // console.log("nishan app.js==========>>>>>>>>", res);
        if (res?.status === 200) {
          localStorage.setItem("token", res?.data?.jwt ?? "");
          setToken(res?.data?.jwt);
          const userData = await AuthApi.userInfo();
          setInfo(userData?.data ?? {});
          setisSignedIn(true);
          localStorage.setItem("name", userData?.data?.name ?? "");
          setJwt(res?.data?.jwt, true);
          setLoading(false);
          setLoginLoader(false);
          googleTagFire("Sign In", null, userData?.data);
        } else {
          setJwt("", false);
          setLoading(false);
          setLoginLoader(false);
          const check = res?.response?.data?.message.includes("Inactive");
          if (check) {
            showMessege("error", res?.response?.data?.message);
            signOut(auth);
          }
        }
      } else {
        setJwt("", false);
        setLoading(false);
        showMessege(
          "error",
          "Error! User doesn't have email address! Please try to sign in with different login system."
        );
      }
    } else {
      setJwt("", false);
      setLoading(false);
      console.error("Token has been expired!");
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(authHandle);
    return subscriber; // unsubscribe on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [navigate]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1BCDB7",
          // fontFamily: archivo?.style?.fontFamily,
        },
      }}
    >
      <Layout style={{ maxHeight: window.innerHeight }}>
        {!deleteAccount && <Header isSignedIn={isSignedIn} />}
        <Layout>
          <Content
            ref={containerRef}
            style={{
              height: "82vh",
              backgroundColor: "#ffffff",
              scrollbarWidth: "none",
            }}
          >
            <div style={{ textAlign: "center", maxHeight: window.innerHeight }}>
              {loading ? <Loader /> : <Navigations />}
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
